import React from 'react';
import styled from 'styled-components';

// Assets
import ProfileImage from '../../assets/profile.png';
import useColour from '../../hooks/useColour';

import { Colour } from '../../types';

type ProfileCircleProps = {
    colour: Colour;
    style?: any;
} 

const ProfileCircle: React.FC<ProfileCircleProps> = (props) => {

    const {lightColour} = useColour(props.colour);

    return <CircleWrapper colour={lightColour}>
        <Image src={ProfileImage} alt="Thomas Léost avatar" />
    </CircleWrapper>

}

const CircleWrapper = styled.div<{colour: string}>`
    background-color: ${({colour}) => colour};
    height: 60px;
    width: 60px;
    border-radius: 60px;
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 30px;
`

export default ProfileCircle;