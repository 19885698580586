import { useStaticQuery, graphql } from 'gatsby';

import { SiteMetaQuery } from '../API';

/**
 * Get sites meta data found in gatsby-config
 * @returns 
 */
const useSiteMeta = () => {
  const data: SiteMetaQuery  = useStaticQuery(graphql`
    query SiteMeta{
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)
  return data;
};


export default useSiteMeta;
