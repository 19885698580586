import React from 'react'
import styled from 'styled-components';

// Components
import ProfileCircle from '../ProfileCircle';
import Card from '../Card';
import { NavHeading, NavItem } from '../Typography';

// Hooks
import useRandomColour from '../../hooks/useRandomColour';
import useColour from '../../hooks/useColour';

const Nav: React.FC = () => {

    const randomColour = useRandomColour();
    const {colour} = useColour(randomColour);

    return (
        <NavWrapper>
            <NavCard>
                <NavLeftWrapper>
                    <ProfileCircle colour={randomColour} />
                    <NavHeading hoverColour={colour} href="/">Thomas Léost</NavHeading>
                </NavLeftWrapper>
                <NavItems>
                    <NavItem hoverColour={colour} href="/#about">About</NavItem>
                    <NavItem hoverColour={colour} href="/#projects">Projects</NavItem>
                    {/* <NavItem hoverColour={colour} href="#tech">Tech</NavItem> */}
                    <NavItem hoverColour={colour} href="/contact">Contact</NavItem>
                    {/* <NavItem hoverColour={colour} href="/blogs">Blog</NavItem> */}
                </NavItems>
            </NavCard>
        </NavWrapper>
    )

}

const NavWrapper = styled.div`
    padding: 10px 30px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    display flex;
    justify-content: center;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
        padding: 10px 10px;   
    }
`
const NavCard = styled(Card)`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 1600px;
    width: 100%;
`
const NavLeftWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`

const NavItems = styled.nav`
    display: flex;
    gap: 20px;
`

export default Nav;