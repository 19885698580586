import theme from '../constants/theme';
import { Colour } from '../types';

const useColour = (colour: Colour) => {

    let darkColour: string = ""
    let lightColour: string = ""

  switch(colour){
    case "Pink": 
      lightColour = theme.color.lightPink
      darkColour = theme.color.pink
      break;
    case "Yellow": 
      lightColour = theme.color.lightYellow
      darkColour = theme.color.yellow
      break;
    case "Green": 
      lightColour = theme.color.lightGreen
      darkColour = theme.color.green
      break;
    case "Blue": 
      lightColour = theme.color.lightBlue
      darkColour = theme.color.blue
      break;
  }

  return {colour: darkColour, lightColour}

}

export default useColour;