import { Colour } from '../types';

const useRandomColour = (): Colour => {
    
    const colours = Object.keys(Colour).map(colour => colour); 
    const index =  Math.floor(Math.random() * colours.length);

    // @ts-expect-error
    return colours[index];
}

export default useRandomColour;