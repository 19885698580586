import React from 'react';
import styled from 'styled-components';


export const Title = styled.h1`
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 3rem;
  margin: 0;
  color: ${({theme}) => theme.color.text};
`
export const Heading = styled.h2`
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  margin: 0;
  color: ${({theme}) => theme.color.text};
`

export const Link = styled.a<{hoverColour?: string}>`
  font-family: baskerville-display-pt, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.2rem;
  margin: 0;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  color: ${({theme}) => theme.color.text};
  &:hover{
      color: ${({theme, hoverColour}) => hoverColour ?? theme.color.lightText};
  }
`

export const SubHeading = styled.h3`
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  margin: 0;
  color: ${({theme}) => theme.color.text};  
`
export const Paragraph = styled.p<{size?: "sm" | "md"}>`
  font-family: baskerville-display-pt, serif;
  font-weight: 400;
  font-style: normal;
  font-size: ${ ({size}) => size? "1rem" : "1.2rem"};
  margin: 0;
  color: ${({theme}) => theme.color.text};
`

export const NavHeading = styled.a<{hoverColour?: string}>`
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  text-decoration: none;
  margin: 0;
  color: ${({theme}) => theme.color.text};
  &:hover{
    color: ${({theme, hoverColour}) => hoverColour ?? theme.color.lightText};
    transform: translateY(-3px);
}
  @media screen and (max-width: ${({theme}) => theme.breakpoints.md}){
    display: none;   
  }
`
export const NavItem = styled.a<{hoverColour?: string}>`
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  margin: 0;
  text-decoration: none;
  color: ${({theme}) => theme.color.text};
  &:hover{
      color: ${({theme, hoverColour}) => hoverColour ?? theme.color.lightText};
      transform: translateY(-3px);
  }
`
