const theme = {
    color: {
      yellow: '#efd18b',
      lightYellow: "#fbeabf",
      blue: '#80c3f0',
      lightBlue: "#ddf7fe",
      pink: '#efbcca',
      lightPink: "#f8e7ef",
      green: '#97e3d2',
      lightGreen: "#d9f7f0",
      background: "#f6f5f5",
      cardBackground: "#ffffff",
      text: "#262626",
      lightText: "#676767"
    },
    breakpoints:{
      lg: "1140px",
      md: "720px",
      sm: "520px"
    }
};

export default theme