import styled from 'styled-components';


const Card = styled.div`
    border-radius: 20px;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 5px 5px 15px -3px #6D6D6D;
`;


export default Card;