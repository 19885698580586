import React from 'react';
import styled from 'styled-components';

import { Paragraph, Link } from '../Typography';
import Card from '../Card';
import {Instagram, Github, LinkedIn} from '../Icons';

// Hooks
import useRandomColour from '../../hooks/useRandomColour';
import useColour from '../../hooks/useColour';


const Footer: React.FC = () => {

  const randomColour = useRandomColour();
  const {colour} = useColour(randomColour);

  return (
    <FooterWrapper>
      <FooterCard>
        <FooterSocials>
          <a noopener noreferrer target="_blank" href="https://www.instagram.com/urbentom_dev/"><Instagram hoverColour={colour} /></a>
          <a noopener noreferrer target="_blank" href="https://github.com/urbentom"><Github hoverColour={colour} /></a>
          <a noopener noreferrer target="_blank" href="https://www.linkedin.com/in/thomas-l%C3%A9ost-58b33793/"><LinkedIn hoverColour={colour} /></a>
        </FooterSocials>
        <FooterNav>
          <FooterLink hoverColour={colour} href="/cookies">Cookies</FooterLink>
          <FooterLink hoverColour={colour} href="/privacy">Privacy</FooterLink>
          <FooterLink hoverColour={colour} href="/sitemap/sitemap-index.xml">Sitemap</FooterLink>
        </FooterNav>
        <FooterText>Made with 💙  by Thomas Léost</FooterText>
      </FooterCard>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer``

const FooterCard = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  box-sizing: border-box;
`

const FooterSocials = styled.div`
display: flex;
gap: 20px;
`

const FooterNav = styled.nav`
  display: flex;
  gap: 20px;
`

const FooterText = styled(Paragraph)`

`
const FooterLink = styled(Link)`
  font-family: futura-pt-bold, sans-serif;
`


export default Footer;